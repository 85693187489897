<script setup>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import FetchData from "@/FetchData";
import {
  computed,
  defineProps,
  onMounted,
  ref,
} from "vue";
import ImageUrl from "@/ImageUrl";
import { FeHeart, FeRepeat, FeMessageSquare } from "@kalimahapps/vue-icons";
import EventBus from "@/EventBus";
// import { createRouter as $dataLayer } from "vue-router";

const product = ref({});
const props = defineProps({
  id: String,
});
const id = computed(() => props.id);
const productDetails = () => {
  const url = '/api/product-details' + id.value;
  FetchData(url, (data) => {
    product.value = data;
  });
};
onMounted(() => {
  // Reload the page when the component is mounted
  productDetails();
});
const method = {
  addToCart(pid) {
    EventBus.emit('addToCart', pid);
  },
  addToWishlist(wid) {
    EventBus.emit('addToWishlist', wid);
  },
};
</script>

<template>
  <Breadcrumb/>
  <div class="container mb-30">
    <div class="row">
      <div class="col-xl-10 col-lg-12 m-auto">
        <div class="product-detail accordion-detail">
          <div class="row mb-50 mt-30">
            <div class="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
              <div class="detail-gallery">
                <span class="zoom-icon"><i class="fi-rs-search"></i></span>
                <!-- MAIN SLIDES -->
                <div  class="product-image-slider">
                  <figure  class="border-radius-10">
                    <img  :src="ImageUrl + product.image" alt="product image" />
                  </figure>
                </div>
              </div>
              <!-- End Gallery -->
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="detail-info pr-30 pl-30">
                <h2 class="title-detail">{{product.name}}</h2>
                <div class="clearfix product-price-cover">
                  <div class="product-price primary-color float-left">
                    <span class="current-price text-brand">৳ {{product.price}} - {{(product.price * 2.5 )}}</span>
                  </div>
                </div>
                <div class="short-desc mb-30">
                  <p class="font-lg">{{product.name}} <span v-for="origin in product.product_origins" :key="origin.id" class="text-info">{{' ('+ origin?.name+') '}}</span>{{' => '+product.category?.name}}{{' => '+product.subcategory?.name}}{{' => '+product.model?.name}}
                    {{' =>'+product.part_number}}
                  </p>
                </div>
                <div class="attr-detail attr-size mb-30">
                  <strong class="mr-10">Origin: </strong>
                  <ul class="list-filter size-filter font-small">
                    <li v-for="origin in product.product_origins" :key="origin.id" class="active"><router-link to="#">{{origin.name}}</router-link></li>
                  </ul>
                </div>
                <div class="attr-detail attr-size mb-30">
                  <strong class="mr-10">Type: </strong>
                  <ul class="list-filter size-filter font-small">
                    <li v-for="type in product.product_types" :key="type.id" class="active"><router-link to="#">{{type.name}}</router-link></li>
                  </ul>
                </div>
                <div class="detail-extralink mb-50">
                  <div class="product-extra-link2">
                    <button type="submit" @click="method.addToCart(product.id)" @keydown="method.addToCart(product.id)"  class="button button-add-to-cart"><FeMessageSquare /> Add to Quote</button>
                    <router-link @click="method.addToWishlist(product.id)" @keydown="method.addToWishlist(product.id)" aria-label="Add To Wishlist" class="action-btn hover-up" to="#!"><FeHeart/></router-link>
                    <router-link aria-label="Compare" class="action-btn hover-up" to="#!"><FeRepeat /></router-link>
                  </div>
                </div>
                <div class="font-xs">
                  <ul class="mr-50 float-start">
                    <li class="mb-5">Part Number:-
                      <span class="text-brand">{{ product.part_number }}</span>
                    </li>
                    <li>Stock:<span class="in-stock text-brand ml-5">{{ product.qty }} Items In Stock</span></li>
                  </ul>
                  <ul class="float-start">
                    <li class="mb-5">Tags:
                      <span class="text-brand">{{ product.product_tag }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Detail Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
