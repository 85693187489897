<script setup>
import ProductList from "@/views/product/ProductFilter.vue";
import { onMounted, ref } from "vue";
import FetchData from "@/FetchData";
import { FeChevronRight, FeChevronDown } from "@kalimahapps/vue-icons";

const categories = ref([]);
const methods = {
  categories() {
    const url = '/api/categories';
    FetchData(url, (data) => {
      categories.value = data;
    });
  },
};
onMounted(() => {
  methods.categories();
});
</script>

<template>
  <nav>
    <ul>
      <li>
        <router-link class="active" to="/">Home </router-link>
      </li>
      <li>
        <router-link class="active" to="/product-list" >Products </router-link>
      </li>
      <li>
        <router-link to="/product-list" >Spare Parts<FeChevronDown/></router-link>
        <ul class="sub-menu">
          <li v-for="category in categories" :key="category.id">
            <router-link :to="{ name: ProductList, params:{ id: category.id}}">{{category.name}}<FeChevronRight class="float-md-end"/></router-link>
            <ul class="level-menu">
              <li v-for="subcategory in category.subcategories" :key="subcategory.id">
                <router-link  :to="`/product-list/`+ category.id + `/`+subcategory.id">{{subcategory.name}}<FeChevronRight class="float-md-end"/></router-link>

                <ul class="level-menus">
                  <li v-for="model in category.subcategories" :key="model.id">
                    <router-link  :to="`/product-list/`+ category.id + `/`+ subcategory.id + `/`+ model.id">{{model.name}}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/product-list">Stationary</router-link>
      </li>
      <li>
        <router-link to="/product-list">Hardware</router-link>
      </li>
      <li>
        <router-link to="/product-list">Needle</router-link>
      </li>
      <li>
        <router-link to="#">Pages <FeChevronDown/></router-link>
        <ul class="sub-menu">
          <li><router-link to="/about-us">About Us</router-link></li>
          <li><router-link to="/contact-us">Contact</router-link></li>
          <li><router-link to="/my-account">My Account</router-link></li>
          <li><router-link to="/login">Login</router-link></li>
          <li><router-link to="/register">Register</router-link></li>
          <li><router-link to="/purchase-guide">Purchase Guide</router-link></li>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
          <li><router-link to="/terms-of-service">Terms of Service</router-link></li>
        </ul>
      </li>
      <li>
        <router-link to="/about-us">About</router-link>
      </li>
      <li>
        <router-link to="/contact-us">Contact</router-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped>

</style>
