<script>
import { defineComponent } from 'vue';
import {
  Carousel,
  Pagination,
  Slide,
  Navigation,
} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Slider',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    sliders: [],
  },
  data() {
    return {
      sliderImageUrl: 'https://globalpartsbd.store/slider/',
    };
  },
});
</script>
<template>
  <div class="home-slide-cover mt-30">
    <Carousel class="hero-slider-1 style-5 dot-style-1 dot-style-1-position-2" :autoplay="3000" :wrap-around="true">
      <Slide v-for="(data, index) in sliders" :key="index" class="single-hero-slider single-animation-wrap" :style="`background-image: url(${sliderImageUrl}${data.image || '/assets/imgs/slider/banner1.png'})`" >
        <div class="slider-content">
          <h1 class="display-2 mb-40">
            {{data.title}}
          </h1>
        </div>
      </Slide>
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>
