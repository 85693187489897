<script setup>
import { defineProps, ref, computed } from 'vue';
import {
  FeMessageCircle,
  FeHeart,
  FeShoppingCart,
  FeEye,
} from "@kalimahapps/vue-icons";
import EventBus from "@/EventBus";
import ImageUrl from "@/ImageUrl";
import Loading from "@/components/loading/Loading.vue";

const props = defineProps({
  topTitle: String,
  todayTopTitle: String,
  topProducts: Array,
  todayTopProducts: Array,
});
const products = computed(() => props.topProducts || props.todayTopProducts);
const title = ref(props.topTitle || props.todayTopTitle);
const defaultImage = ref("/comeingSoon.png");
const method = {
  addToCart(id) {
    EventBus.emit('addToCart', id);
  },
  addToWishlist(id) {
    EventBus.emit('addToWishlist', id);
  },
  handleImageError(event) {
    // Set the source to the default image URL
    event.target.src = defaultImage.value;
  },
};
</script>

<template>
  <section class="product-tabs section-padding position-relative">
    <div class="container">
      <div class="section-title style-2 wow animate__animated animate__fadeIn">
        <h3>{{title}}</h3>
      </div>
      <!--End nav-tabs-->
      <div class="tab-content" >
        <div class="tab-pane fade show active">
          <div class="row product-grid-4">
            <div v-if="products.length === 0" class="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <h1  class="display-2 mb-30">Products Not Available</h1>
            </div>
            <div v-else v-for="product in products" :key="product.product_id" class="col-lg-1-5 col-md-4 col-12 col-sm-6">
              <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                <div class="product-img-action-wrap">
                  <div class="product-img product-img-zoom">
                    <router-link to="/product-details">
                      <img class="default-img" @error="method.handleImageError" :src="ImageUrl+ product.product.image" alt="" />
                      <img class="hover-img" @error="method.handleImageError" :src="ImageUrl+ product.product.image" alt="" />
                    </router-link>
                  </div>
                  <div class="product-action-1">
                    <router-link @click="method.addToWishlist(product.product_id)" aria-label="Add To Wishlist" class="action-btn" to="#"><FeHeart/></router-link>
                    <router-link aria-label="Add To Quote" @click="method.addToCart(product.product_id)" class="action-btn" to="#"><FeShoppingCart /></router-link>
                    <a aria-label="Quick view" class="action-btn" data-bs-toggle="modal" data-bs-target="#quickViewModal"><FeEye /></a>
                  </div>
                </div>
                <div class="product-content-wrap">
                  <h2><router-link to="shop-product-right.html">{{product.product.name}}</router-link></h2>
                  <div class="product-card-bottom">
                    <div class="product-price">
                      <span>{{product.total_sold}} PCS</span>
                    </div>
                    <div class="add-cart">
                      <router-link to="#!" @click="method.addToCart(product.product_id)" class="add" ><FeMessageCircle /> Add Quote</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--End product-grid-4-->
        </div>
      </div>
      <!--End tab-content-->
    </div>
  </section>
</template>
