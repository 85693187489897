<script setup>
import { onMounted, ref } from "vue";
import EventBus from "@/EventBus";
import { toast } from "vue3-toastify";

const show = ref(false);
const toggleModalOpenClass = (add) => {
  show.value = !show.value;
  if (add) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
  }
};
onMounted(() => {
  EventBus.on('productViewModal', (product) => {
    toast.info('Quick View Product Under Maintenance');
  });
});
</script>

<template>
  <div class="modal fade custom-modal" :class="[{'show': show},{'displayNone':show}]" tabindex="-1" aria-labelledby="quickViewModalLabel" aria-label="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" @click="toggleModalOpenClass" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
              <div class="detail-gallery">
                <span class="zoom-icon"><i class="fi-rs-search"></i></span>
                <!-- MAIN SLIDES -->
                <div class="product-image-slider">
                  <figure class="border-radius-10">

                  </figure>
                </div>
              </div>
              <!-- End Gallery -->
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="detail-info pr-30 pl-30">
                <h3 class="title-detail" >name</h3>
                <div class="clearfix product-price-cover">
                  <div class="product-price primary-color float-left">
                    <span  class="font-xl text-brand">0.00</span>
                    <span  class="font-xl ml-15">0.00</span>
                  </div>
                </div>
                <div class="detail-extralink mb-30">
                  <div class="product-extra-link2" >
                    <button type="submit" class="button button-add-to-cart"><i class="fi-rs-quote-right"></i>Add to Quote</button>
                  </div>
                </div>
                <div class="font-xs">
                  <ul class="mr-50 float-start">
                    <li class="mb-5">Vendor: <span class="text-brand">GlobalPartsBD</span></li>
                    <li  class="mb-5">Part Number:<span  class="text-brand">1546285</span></li>
                  </ul>
                  <ul class="float-start">
                    <li>Stock:<span id="quickViewQty" class="in-stock text-brand ml-5"> 6</span></li>
                  </ul>
                </div>
              </div>
              <!-- Detail Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.displayNone{
  display: block;
  padding-right: 17px;
}
</style>
