<template>
  <div class="pagination-area mt-20 mb-20">
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-start">
        <li class="page-item">
          <a class="page-link" @keydown.enter="goToPage(currentPage - 1)" @click="goToPage(currentPage - 1)" :disabled="!hasPrevPage"><FeArrowLeft/></a>
        </li>
        <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ 'active': page === currentPage }">
          <a class="page-link" @keydown.enter="goToPage(page)" @click="goToPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" v-if="props.totalPages > props.maxVisiblePages && !showEllipsisAfter">
          <a class="page-link" @keydown.enter="goToPage(props.totalPages)" @click="goToPage(props.totalPages)">{{ props.totalPages }}</a>
        </li>
        <li class="page-item" v-if="showEllipsisAfter">
          <a class="page-link dot" href="#">...</a>
        </li>
        <li class="page-item" v-if="showEllipsisAfter">
          <a class="page-link" @keydown.enter="goToPage(props.totalPages)" @click="goToPage(props.totalPages)">{{ props.totalPages }}</a>
        </li>
        <li class="page-item">
          <a class="page-link" @keydown.enter="goToPage(currentPage + 1)" @click="goToPage(currentPage + 1)" :disabled="!hasNextPage"><FeArrowRight/></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { FeArrowLeft, FeArrowRight } from "@kalimahapps/vue-icons";
import { computed, defineProps, defineEmits } from 'vue';
import EventBus from "@/EventBus";

const props = defineProps({
  currentPage: Number,
  totalPages: Number,
  maxVisiblePages: { type: Number, default: 5 }, // Maximum visible pages (default: 5)
});

const hasPrevPage = computed(() => props.currentPage > 1);
const hasNextPage = computed(() => props.currentPage < props.totalPages);

const goToPage = (page) => {
  if (page >= 1 && page <= props.totalPages) {
    EventBus.emit('page-change', page);
  }
};

// Calculate the visible pages
const visiblePages = computed(() => {
  let start = 1;
  let end = props.totalPages;

  // Adjust start and end if the total number of pages is greater than maxVisiblePages
  if (props.totalPages > props.maxVisiblePages) {
    start = Math.max(1, props.currentPage - Math.floor(props.maxVisiblePages / 2));
    end = Math.min(props.totalPages, start + props.maxVisiblePages - 1);

    // Adjust start again if the end is near the total pages
    if (end === props.totalPages) {
      start = Math.max(1, end - props.maxVisiblePages + 1);
    }
  }

  const pages = [];
  let page = start;
  while (page <= end) {
    pages.push(page);
    page += 1; // Increment the page variable
  }
  return pages;
});
// Determine if ellipsis should be displayed after the visible pages
const showEllipsisAfter = computed(() => props.totalPages > props.maxVisiblePages && visiblePages.value[visiblePages.value.length - 1] < props.totalPages);

</script>
