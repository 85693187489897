<script >
import axios from "axios";
import ProductList from '@/views/product/ProductList.vue';
import Multiselect from "vue-multiselect";
import FetchData from "@/FetchData";
import Pagination from "@/components/pagination/Pagination.vue";
import EventBus from "@/EventBus";
import Loading from "@/components/loading/Loading.vue";
import { toast } from "vue3-toastify";

export default {
  name: "ProductFilter",
  props: {
    id: String,
    subId: String,
    modelId: String,
  },
  components: {
    Loading,
    Pagination,
    Multiselect,
    ProductList,
  },
  data() {
    return {
      pagination: {},
      dataList: [],
      categories: [],
      subCategories: [],
      modals: [],
      productType: [],
      cat: this.id,
      subCat: this.subId,
      model: this.modelId,
      categoryId: null,
      subCategoryId: null,
      modalId: null,
      isLoading: false,
    };
  },
  mounted() {
    EventBus.on('page-change', (page) => {
      this.filterMoreProduct(page);
    });
    EventBus.on('change-data', () => {
      this.filterMoreProduct();
    });
    EventBus.on('searchUser', (searchData) => {
      this.searchProduct(searchData);
    });
    this.productFilter();
    this.categoriesData();
  },
  methods: {
    searchProduct(searchData) {
      const url = '/api/product-list/' + searchData;
      this.searchData = searchData;
      this.loading = true;
      this.isOpen = true;
      FetchData(url, (data) => {
        if (data.message === 'Product Found') {
          this.dataList = data.data;
          this.pagination = data;
          this.isLoading = false;
          toast.success(data.message);
        } else {
          toast.error(data.message);
          this.isOpen = false;
        }
      });
    },
    productFilter(page) {
      this.isLoading = true;
      const url = `https://globalpartsbd.store/api/product-filter?page=${page}`;
      axios.post(url, {
        id: this.cat,
        subId: this.subCat,
        modelId: this.model,
      }).then((res) => {
        this.dataList = res.data.data;
        this.pagination = res.data;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    filterMoreProduct(page) {
      this.isLoading = true;
      const url = `https://globalpartsbd.store/api/product-filter?page=${page}`;
      axios.post(url, {
        id: this.categoryId?.id,
        subId: this.subCategoryId?.id,
        modelId: this.modalId?.id,
      }).then((res) => {
        this.dataList = res.data.data;
        this.pagination = res.data;
        this.subCategoriesData(this.categoryId.id);
        if (this.subCategoryId.id) {
          this.modal(this.categoryId.id, this.subCategoryId.id);
        }
        this.isLoading = false;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    categoriesData() {
      const url = '/api/category';
      FetchData(url, (data) => {
        this.categories = data;
      });
    },
    subCategoriesData(id) {
      const url = '/api/subcategories/' + id;
      FetchData(url, (data) => {
        this.subCategories = data;
      });
    },
    modal(id, sub) {
      const url = '/api/model/' + id + '/' + sub;
      FetchData(url, (data) => {
        this.modals = data;
      });
    },
  },
};
</script>
<template>
  <div class="container mb-30 mt-30">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-lg-12">
          <a class="shop-filter-toogle" href="#filterHeader">
            <span class="fi-rs-filter mr-5"></span>
            Filters
            <i class="fi-rs-angle-small-down angle-down"></i>
            <i class="fi-rs-angle-small-up angle-up"></i>
          </a>
          <div class="shop-product-fillter-header" id="filterHeader">
            <div class="row">
              <div class="col-xl-3 col-lg-6 col-md-6 mb-lg-0 mb-md-2 mb-sm-2">
                <h5 class="mb-30">Category</h5>
                <multiselect
                  placeholder="Search Category"
                  label="name" track-by="id"
                  :options="categories"
                  :clear-on-select="true"
                  v-model="categoryId"
                  @select="filterMoreProduct"
                  selectedLabel="selectedLabel"
                />
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 mb-lg-0 mb-md-2 mb-sm-2">
                <h5 class="mb-30">Sub Category</h5>
                <multiselect
                  v-model="subCategoryId"
                  placeholder="Search Sub Category"
                  label="name" track-by="id"
                  :options="subCategories"
                  :clear-on-select="true"
                  @select="filterMoreProduct"
                />
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 mb-lg-0 mb-md-2 mb-sm-2">
                <h5 class="mb-30">Model</h5>
                <multiselect
                  v-model="modalId"
                  placeholder="Search Modals"
                  label="name" track-by="id"
                  :options="modals"
                  :clear-on-select="true"
                  @select="filterMoreProduct"
                />
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 mb-lg-0 mb-md-2 mb-sm-2">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="shop-product-fillter">
          <div class="totall-product">
            <p>We found <strong class="text-brand">{{pagination.total}}</strong> items for you!</p>
          </div>
          <pagination
            :current-page="pagination.current_page"
            :total-pages="pagination.last_page"
          />
        </div>
        <div v-if="isLoading" class="row product-grid">
          <Loading/>
        </div>
        <div v-else-if="dataList.length === 0" class="row product-grid">
          <h1  class="display-2 pl-20 mb-30">Products Not Available</h1>
        </div>
        <ProductList v-else :dataList="dataList"/>
        <pagination
          :current-page="pagination.current_page"
          :total-pages="pagination.last_page"
        />
      </div>
    </div>
  </div>
</template>
