import axios from "axios";
import { toast } from "vue3-toastify";

const defaultUrl = "https://globalpartsbd.store";
const fetchData = (url, successCallback) => {
  axios.get(defaultUrl + url)
    .then((res) => {
      if (res.data) {
        successCallback(res.data);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export default fetchData;
