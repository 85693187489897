import { createApp } from 'vue';
import App from "@/App.vue";
import router from "@/router/router";
import Multiselect from 'vue-multiselect';
import store from '@/store';

const app = createApp(App);
app.use(router);
app.use(store);
app.component('Multiselect', Multiselect);
// Mount the app
app.mount("#app");
