<script setup>

</script>

<template>
  <div class="col-md-12 ">
    <div class="text-center">
      <img :src="'/11.gif'" height="300" width="300" alt="GSB" />
    </div>
  </div>
</template>
