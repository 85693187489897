<template>
  <section class="newsletter mb-15 wow animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="position-relative newsletter-inner">
            <div class="newsletter-content">
              <h2 class="mb-20">
                Stay home & get your daily <br />
                needs from our Store
              </h2>
              <p class="mb-45">Start You'r Daily Shopping with <span class="text-brand">GlobalPartsBD</span></p>
              <form class="form-subcriber d-flex">
                <input type="email" placeholder="Your email address" />
                <button class="btn" type="submit">Subscribe</button>
              </form>
            </div>
            <img src="/newslatter.png" alt="newsletter" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FooterNewsLater',
};
</script>
