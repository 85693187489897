<script setup>
import {
  ref,
  defineProps,
  computed,
} from 'vue';
import { FeChevronDown, FeGrid } from "@kalimahapps/vue-icons";

const props = defineProps({
  categories: Array,
});

const categories = computed(() => props.categories);
const isDropdownOpen = ref(false);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};
</script>

<template>
  <div class="main-categori-wrap d-none d-lg-block">
    <router-link to class="categories-button-active" @click="toggleDropdown">
      <FeGrid /><span class="et">All</span> Categories
      <FeChevronDown/>
    </router-link>
    <div class="categories-dropdown-wrap categories-dropdown-active-large font-heading" :class="{ 'open': isDropdownOpen }" ref="dropdownRef">
      <div class="d-flex categori-dropdown-inner">
        <ul>
          <li v-for="category in categories.slice(0, 10)" :key="category.id">
            <router-link :to="`/product-list/` + category.id" target="_blank">
              <img
                src="#"
                alt="" />{{ category.name }}
            </router-link>
          </li>
        </ul>
        <ul class="end">
          <li v-for="category in categories.slice(10, 20)" :key="category.id">
            <router-link :to="`/product-list/` + category.id" target="_blank"> <img
              src="#"
              alt="" />{{ category.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
