<template>
  <div class="search-style-2" style="border: none !important;">
    <input type="text" v-model="search" @keydown.enter="searchData" placeholder="Search With Part Number Or Product Name"/>
    <span @click="searchData" @keydown.enter="searchData" class="btn btn-success-gradien">Search</span>
  </div>
</template>

<script>
import EventBus from "@/EventBus";
import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css';
// import { useRouter } from "vue-router";
import router from "@/router/router";

export default {
  data() {
    return {
      search: '',
    };
  },
  watch: {
    search() {
      if (this.search.length === 0) {
        EventBus.emit('searchCheck', this.search);
      }
    },
  },
  methods: {
    searchData() {
      if (this.search) {
        router.push({ path: '/product-list/' });
        EventBus.emit('searchUser', this.search);
        toast.error('Router is undefined');
      } else {
        toast.error('Please Type Your Search Terms');
      }
    },
  },
};
</script>

<style scoped>

</style>
