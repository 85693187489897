import {
  createRouter,
  createWebHistory,
} from 'vue-router';
import Home from '@/views/home/Index.vue';
import Quotation from "@/views/quotation/Quotation.vue";
import Wishlist from "@/views/wishlist/Wishlist.vue";
import ProductDetails from "@/views/product/ProductDetails.vue";
import ProductList from "@/views/product/ProductFilter.vue";
import NotFound from "@/components/404NotFound/NotFound.vue";

const routes = [
  {
    path: '/',
    component: Home,
    meta: { title: 'Home' }, // Specify the title for the Home route
  },
  {
    path: '/quotation',
    component: Quotation,
    meta: { title: 'Quotation' }, // Specify the title for the Quotation route
  },
  {
    path: '/wishlist',
    component: Wishlist,
    name: Wishlist,
    meta: { title: 'Wishlist' }, // Specify the title for the Quotation route
  },
  {
    path: '/product-list/:id?/:subId?/:modelId?',
    component: ProductList,
    name: ProductList,
    meta: { title: 'Product List' }, // Specify the title for the Quotation route
    props: true,
  },
  {
    path: '/product-details/:id/:name?',
    name: 'ProductDetails',
    component: ProductDetails,
    meta: { title: 'Product Details', reloadPage: true }, // Specify the title for the Quotation route
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: { title: 'Page Not Found' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Global Parts BD'; // Set the page title based on the route's meta field
  next();
});

export default router;
