// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    dynamicData: {},
  },
  mutations: {
    setData(state, { key, data }) {
      state.dynamicData[key] = data;
    },
  },
  actions: {
    async fetchData({ commit }, { key, url }) {
      // Fetch data based on the provided URL
      const response = await fetch(url);
      const data = await response.json();

      commit('setData', { key, data });
    },
  },
  getters: {
    getData: (state) => (key) => state.dynamicData[key] || null,
  },
});
