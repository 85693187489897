<template>
  <div id="app">
    <QuickView/>
    <Layout/>
  </div>
</template>

<script>
import Layout from "@/Layout.vue";
import QuickView from "@/components/quickview/QuickView.vue";
import { onMounted } from "vue";

export default {
  name: 'App',
  components: {
    Layout,
    QuickView,
  },
};
</script>
