<script setup>
const methods = {
  getCurrentYear() {
    return new Date().getFullYear();
  },
};
</script>

<template>
  <div class="container pb-30 wow animate__animated animate__fadeInUp" data-wow-delay="0">
    <div class="row align-items-center">
      <div class="col-12 mb-30">
        <div class="footer-bottom"></div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <p class="font-sm mb-0">
          Copyright © {{ methods.getCurrentYear() }}, <strong class="text-brand">GSB</strong> - GlobalPartsBD
          <br />All rights reserved
        </p>
      </div>
      <div class="col-xl-6 col-lg-6 text-center d-none d-xl-block">
        <div class="hotline d-lg-inline-flex mr-30">
          <img :src="'/assets/imgs/icons/phone-call.svg'" alt="hotline" />
          <p>+880 1763-970006<span>Working 8:00 - 22:00</span></p>
        </div>
        <div class="hotline d-lg-inline-flex">
          <img :src="'/assets/imgs/icons/phone-call.svg'" alt="hotline" />
          <p>+880 1763-970006<span>24/7 Support Center</span></p>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 text-end d-none d-md-block">
        <div class="mobile-social-icon">
          <h6>Follow Us</h6>
          <a href="https://www.facebook.com/globalpartsbd1" target="_blank"><img :src="'/assets/imgs/icons/icon-facebook-white.svg'" alt="" /></a>
          <router-link target="_blank" to="#"><img :src="'/assets/imgs/icons/icon-twitter-white.svg'" alt="" /></router-link>
          <router-link target="_blank" to="#"><img :src="'/assets/imgs/icons/icon-instagram-white.svg'" alt="" /></router-link>
          <router-link target="_blank" to="#"><img :src="'/assets/imgs/icons/icon-pinterest-white.svg'" alt="" /></router-link>
          <router-link target="_blank" to="#"><img :src="'/assets/imgs/icons/icon-youtube-white.svg'" alt="" /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
