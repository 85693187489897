<template>
    <div class="mobile-search search-style-3 mobile-header-border">
      <input type="text" v-model="search" @keydown.enter="methode.searchData" placeholder="Search Part Number Or Product Name"/>
      <button @click="methode.searchData" @keydown.enter="methode.searchData"><FeSearch /></button>
    </div>
</template>

<script setup>
import EventBus from "@/EventBus";
import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css';
import {
  FeSearch,
} from "@kalimahapps/vue-icons";
import { ref } from "vue";

const search = ref(null);
const methode = {
  searchData() {
    if (this.search) {
      EventBus.emit('searchUser', this.search); // Use this.$emit to emit the event
    } else {
      toast.error('Please Type Your Search Terms');
    }
  },
};
</script>
