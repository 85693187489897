<script setup>
import { onMounted, ref } from "vue";
import CategoryDropdown from "@/components/navbar/CategoryDropdown.vue";
import FetchData from "@/FetchData";
import ProductList from "@/views/product/ProductFilter.vue";
import EventBus from "@/EventBus";
import ManuList from "@/components/navbar/ManuList.vue";

const categories = ref([]);
const methods = {
  categories() {
    const url = '/api/categories';
    FetchData(url, (data) => {
      categories.value = data;
    });
  },
  changeData() {
    EventBus.emit('change-data');
  },
  openMobile() {
    EventBus.emit('mobile-manu-toggle');
  },
};
onMounted(() => {
  methods.categories();
});
</script>
<template>
  <div class="header-bottom header-bottom-bg-color sticky-bar">
    <div class="container">
      <div class="header-wrap header-space-between position-relative">
        <div class="logo logo-width-1 d-block d-lg-none">
          <router-link to="/"><img :src="'/logo.png'" alt="GSB" /></router-link>
        </div>
        <div class="header-nav d-none d-lg-flex">
          <CategoryDropdown :categories="categories"/>
          <div class="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
            <ManuList/>
          </div>
        </div>
        <div class="hotline d-none d-lg-flex">
          <img :src="'/assets/imgs/icons/icon-headphone-white.svg'" alt="hotline" />
          <p>+880 1763-970006 <span>24/7 Support Center</span></p>
        </div>
        <div @click="methods.openMobile()" @keydown.enter="methods.openMobile()" class="header-action-icon-2 d-block d-lg-none">
          <div class="burger-icon burger-icon-white">
            <span class="burger-icon-top"></span>
            <span class="burger-icon-mid"></span>
            <span class="burger-icon-bottom"></span>
          </div>
        </div>
        <div class="header-action-right d-block d-lg-none">
          <div class="header-action-2">
            <div class="header-action-icon-2">
              <a href="shop-wishlist.html">
                <img alt="Nest" src="assets/imgs/theme/icons/icon-heart.svg" />
                <span class="pro-count white">4</span>
              </a>
            </div>
            <div class="header-action-icon-2">
              <a class="mini-cart-icon" href="shop-cart.html">
                <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                <span class="pro-count white">2</span>
              </a>
              <div class="cart-dropdown-wrap cart-dropdown-hm2">
                <ul>
                  <li>
                    <div class="shopping-cart-img">
                      <a href="shop-product-right.html"><img alt="Nest" src="assets/imgs/shop/thumbnail-3.jpg" /></a>
                    </div>
                    <div class="shopping-cart-title">
                      <h4><a href="shop-product-right.html">Plain Striola Shirts</a></h4>
                      <h3><span>1 × </span>$800.00</h3>
                    </div>
                    <div class="shopping-cart-delete">
                      <a href="#"><i class="fi-rs-cross-small"></i></a>
                    </div>
                  </li>
                  <li>
                    <div class="shopping-cart-img">
                      <a href="shop-product-right.html"><img alt="Nest" src="assets/imgs/shop/thumbnail-4.jpg" /></a>
                    </div>
                    <div class="shopping-cart-title">
                      <h4><a href="shop-product-right.html">Macbook Pro 2022</a></h4>
                      <h3><span>1 × </span>$3500.00</h3>
                    </div>
                    <div class="shopping-cart-delete">
                      <a href="#"><i class="fi-rs-cross-small"></i></a>
                    </div>
                  </li>
                </ul>
                <div class="shopping-cart-footer">
                  <div class="shopping-cart-total">
                    <h4>Total <span>$383.00</span></h4>
                  </div>
                  <div class="shopping-cart-button">
                    <a href="shop-cart.html">View cart</a>
                    <a href="shop-checkout.html">Checkout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
