<template>
  <div class="container mb-30" v-if="isOpen" :style="{ position: 'absolute', opacity: 1, zIndex: 999 }">
    <div class="col-xl-10 col-lg-12 m-auto" style="background: white;">
      <div class="table-responsive shopping-summery">
        <table class="table table-wishlist">
          <thead>
          <tr class="main-heading">
            <th scope="col" class="pl-20">Image</th>
            <th scope="col">Product Name </th>
            <th scope="col">Model Number </th>
            <th scope="col">Part Number</th>
            <th v-if="admin" scope="col">Box Number</th>
            <th scope="col">Stock Status</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="6">
              <div class="col-md-12 ">
                <div class="text-center">
                  <img :src="'11.gif'" alt="GSB" />
                </div>
              </div>
            </td>
          </tr>
          <tr v-else-if="products.length===0">
            <td colspan="6" class="text-center">Data Not Available</td>
          </tr>
          <tr v-else class="pt-30" v-for="product in products" :key="product.id">
            <td class="image product-thumbnail pt-40 pl-20">
              <img @error="handleImageError" :src="ImageUrl()+product.image" alt="GSB" />
            </td>
            <td class="product-des product-name" style="max-width: 300px;">
              <h6><a class="product-name mb-10" href="">{{product.name}}</a></h6>
            </td>
            <td  class="product-des product-name">
              <h6><a class="product-name mb-10" href="">{{product.modal_name}}</a></h6>
            </td>
            <td class="price" style="max-width: 250px;" data-title="Price">
              <h6 class="text-brand">{{product.part_number}}</h6>
            </td>
            <td v-if="admin" class="price" style="max-width: 250px;" data-title="Price">
              <h6 class="text-brand">{{product.product_location}}</h6>
            </td>
            <td class="text-center detail-info" data-title="Stock">
              <span class="stock-status in-stock mb-0"> {{product.qty}} In Stock</span>
            </td>
            <td class="text-right" data-title="Cart">
              <button @click="addToCart(product.id)" title="You are about To Add This Product To your Quote" class="btn btn-sm mr-10">Add to Quote</button>
              <button @click="addToWishlist(product.id)" title="You are about To Add This Product To your Wishlist" class="btn btn-sm">Add to Wishlist</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import EventBus from "@/EventBus";
import ImageUrl from "@/ImageUrl";
import PostData from "@/PostData";
import fetchData from "@/FetchData";

export default {
  name: "ProductShowToUser",
  data() {
    return {
      products: [],
      loading: false,
      searchData: '',
      admin: '',
      isOpen: false,
      defaultImage: "/comeingSoon.png",
    };
  },
  watch: {
    searchData() {
      if (this.searchData.length === 0) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    EventBus.on('searchCheck', (searchData) => {
      this.searchData = searchData;
    });
    // EventBus.on('searchUser', (searchData) => {
    //   const url = '/api/product-list/' + searchData;
    //   this.searchData = searchData;
    //   this.loading = true;
    //   this.isOpen = true;
    //   fetchData(url, (data) => {
    //     if (data.message === 'Product Found') {
    //       this.products = data.data;
    //       toast.success(data.message);
    //       this.loading = false;
    //     } else {
    //       toast.error(data.message);
    //       this.isOpen = false;
    //     }
    //   });
    // });
    EventBus.on('addToCart', (id) => {
      this.addToCart(id);
    });
    EventBus.on('addToWishlist', (id) => {
      this.addToWishlist(id);
    });
  },
  methods: {
    ImageUrl() {
      return ImageUrl;
    },
    addToCart(i) {
      const url = '/api/add-to-cart';
      const dataPayload = {
        qty: 1,
        id: i,
      };
      PostData(url, dataPayload, (data) => {
        if (data.message === 'Product Add To Quotation') {
          toast.success(data.message);
          EventBus.emit('cartUpdate');
        } else {
          toast.warning(data.message);
        }
      });
    },
    addToWishlist(i) {
      const url = '/api/add-to-wishlist';
      const dataPayload = {
        qty: 1,
        id: i,
      };
      PostData(url, dataPayload, (data) => {
        if (data.message === 'Product Add to Wishlist') {
          toast.success(data.message);
          EventBus.emit('wishlistUpdate');
        } else {
          toast.warning(data.message);
        }
      });
    },
    handleImageError(event) {
      // Set the source to the default image URL
      event.target.src = this.defaultImage;
    },
  },
};
</script>
