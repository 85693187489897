<template>
  <footer class="main">
    <FooterNewsLater/>
    <FooterMiddle/>
    <FooterBottom/>
  </footer>
</template>
<script>
import FooterBottom from "@/components/footer/FooterBottom.vue";
import FooterMiddle from "@/components/footer/FooterMiddle.vue";
import FooterNewsLater from "@/components/footer/FooterNewsLater.vue";

export default {
  name: 'Footer',
  components: {
    FooterNewsLater,
    FooterMiddle,
    FooterBottom,
  },
};
</script>
