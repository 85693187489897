<script setup>
import { onMounted, ref } from "vue";
import EventBus from "@/EventBus";
import { toast } from "vue3-toastify";
import ImageUrl from "@/ImageUrl";
import fetchData from "@/FetchData";

const products = ref([]);
const defaultImage = ref("/comeingSoon.png");
const methode = {
  addToCart(id) {
    EventBus.emit('addToCart', id);
  },
  wishListData() {
    const url = '/api/wishlist-data';
    fetchData(url, (data) => {
      products.value = data;
    });
  },
  DeleteWishListData(id) {
    const url = `/api/wishlist-data-delete/` + id;
    fetchData(url, (data) => {
      toast.success(data.message);
      this.wishListData();
      EventBus.emit('wishlistUpdate');
    });
  },
  handleImageError(event) {
    // Set the source to the default image URL
    event.target.src = defaultImage.value;
  },
};
onMounted(() => {
  EventBus.on('wishlistUpdate', () => {
    methode.wishListData();
  });
  methode.wishListData();
});
</script>

<template>
  <div class="container mb-30 mt-50" id="reloadWishlistMain">
    <div class="row">
      <div class="col-xl-10 col-lg-12 m-auto">
        <div class="mb-50">
          <h1 class="heading-2 mb-10">Your Wishlist</h1>
          <h6 class="text-body">
            There are <span class="text-brand">{{ products.length }}</span> products in this list
          </h6>
        </div>
        <div class="table-responsive shopping-summery">
          <table class="table table-wishlist">
            <thead>
            <tr class="main-heading">
              <th scope="col" class="pl-20" colspan="2">Product</th>
              <th scope="col">Stock Status</th>
              <th scope="col" class="end">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr class="pt-30" v-if="products.length === 0">
              <td colspan="4" class="text-center">Data Not Available</td>
            </tr>
            <tr class="pt-30" v-else v-for="item in products" :key="item.id">
              <td class="image product-thumbnail pt-40 pl-20">
                <img :src="ImageUrl+item.product.image" @error="methode.handleImageError" :alt="GSBIMG">
              </td>
              <td class="product-des product-name">
                <h6>
                  <a class="product-name mb-10" :href="'/product-details-' + item.product.id + '/' + item.product.slug" target="_blank">
                    {{ item.product.name }}
                  </a>
                </h6>
              </td>
              <td class="text-center detail-info" data-title="Stock">
                  {{ item.product.qty  + ' In Stock' }}
              </td>
              <td class="text-right" data-title="Cart">
                <button @click="methode.addToCart(item.product.id)" title="You are about To Add This Product To your Quote" class="btn btn-sm mr-10">Add to Quote</button>
                <button @click="methode.DeleteWishListData(item.id)" title="You are about To Delete" class="btn btn-sm text-danger">Delete</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
