<template>
  <div class="container mb-80 mt-50">
    <div class="row">
      <div class="col-lg-12 mb-40">
        <h1 class="heading-2 mb-10">Your Quotation</h1>
        <div class="d-flex justify-content-between">
          <h6 class="text-body">There are <span class="text-brand">{{ products.length }}</span> products in your Quotation</h6>
          <h6 class="text-body">
            <button type="button" name="clear_quotation" @click="methode.clearQuotation" class="btn mr-10 mb-sm-15"><i class="fi-rs-trash mr-5 text-danger"></i>Clear Quotation</button>
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive shopping-summery">
          <table class="table table-wishlist">
            <thead>
            <tr class="main-heading">
              <th scope="col" class="pl-20">Product Image</th>
              <th scope="col">Product Info</th>
              <th scope="col" style="width: 250px">Origin</th>
              <th scope="col">Part Number</th>
              <th scope="col">Quantity</th>
              <th scope="col" class="end">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr class="pt-30" v-if="products.length === 0">
              <td colspan="6" class="text-center">Data Not Available</td>
            </tr>
            <tr class="pt-30" v-else v-for="(data, index) in products" :key="index">
              <td class="image product-thumbnail pt-40 pl-20">
                <img @error="methode.handleImageError" :src="imageUrl+data.product.image" alt="GSBD"/>
              </td>
              <td class="product-des product-name">
                {{ data.product.name }}
              </td>
              <td>
<!--                <select class="form-control js-example-basic-multiple select-active" multiple="multiple" v-model="selectedOrigins[index]">-->
<!--                  <option v-for="(origin, originIndex) in productOrigins" :key="originIndex" :value="origin.name">{{ origin.name }}</option>-->
<!--                </select>-->
              </td>
              <td>
                {{ data.product.part_number }}
              </td>
              <td class="text-center detail-info" data-title="Stock">
                <div class="detail-extralink mr-15">
                  <div class="detail-qty border radius">
<!--                    <a href="#" class="qty-down" @click="decrementQuantity(index)"><i class="fi-rs-angle-small-down"></i></a>-->
<!--                    <input type="text" :id="'updateQty' + data.id" :name="'qty[' + index + ']'" class="qty-val" v-model="data.qty" min="1" />-->
<!--                    <a href="#" class="qty-up" @click="incrementQuantity(index)"><i class="fi-rs-angle-small-up"></i></a>-->
                  </div>
                </div>
              </td>
              <td class="action text-center font-heading" data-title="Remove">
                <div class="product-action-1 font-xl">
                  <a aria-label="Quick view" @click="methode.underMaintanace" @keydown.enter="methode.underMaintanace" class="action-btn" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i class="fi-rs-eye"></i></a>
                  <a aria-label="Delete" class="action-btn text-danger" @click="methode.cartDelete(data.id)" @keydown.enter="methode.cartDelete(data.id)"><FeX class="text-danger"/></a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="form-group mb-30">
            <textarea rows="5" name="comments" placeholder="Additional information"></textarea>
          </div>
        </div>
        <div class="divider-2 mb-30"></div>
        <div class="cart-action d-flex justify-content-between">
          <router-link to="/" class="btn"><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</router-link>
          <button class="btn  mr-10 mb-sm-15" name="update_quotation" @click="methode.underMaintanace"><i class="fi-rs-refresh mr-10"></i>Update Quotation</button>
          <button type="submit" name="submit_quotation" class="btn mr-10 mb-sm-15" @click="methode.underMaintanace"><i class="fi-rs-sign-out mr-10"></i> Proceed To Send</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { FeX } from "@kalimahapps/vue-icons";
import { onMounted, ref } from "vue";
import EventBus from "@/EventBus";
import { toast } from "vue3-toastify";
import imageUrl from "@/ImageUrl";
import FetchData from "@/FetchData";

const productCount = ref(0);
const products = ref([]);
const defaultImage = ref("/comeingSoon.png");
const methode = {
  clearQuotation() {
    const url = '/api/cart-clear';
    FetchData(url, (data) => {
      if (data.message) {
        toast.success(data.message);
        EventBus.emit('cartUpdate');
      } else {
        toast.error(data.error);
      }
    });
  },
  cartDelete(id) {
    EventBus.emit('cartDelete', id);
  },
  handleImageError(event) {
    // Set the source to the default image URL
    event.target.src = defaultImage.value;
  },
  underMaintanace() {
    toast.info('This Content is Under Maintanace');
  },
};
onMounted(() => {
  EventBus.emit('cartUpdate');
  EventBus.on('sendData', (data) => {
    products.value = data;
    productCount.value = data.length;
  });
});
</script>
