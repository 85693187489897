<script setup>
import Slider from "@/views/slider/Slider.vue";
import fetchData from "@/FetchData";
import { watchEffect, ref, computed } from "vue";

const sliders = ref([]);
const sliderImageUrl = ref('https://globalpartsbd.store/slider/');
const method = {
  slider() {
    const url = '/api/slider/list';
    fetchData(url, (data) => {
      sliders.value = data;
    });
  },
};
watchEffect(() => {
  method.slider();
});
// Use computed to declare the filteredSliders computed property
const filteredSliders = computed(() => sliders.value.filter((data) => data.content_type === 'Slider'));
const filteredOffers = computed(() => sliders.value.filter((data) => data.content_type === 'Offer').slice(0, 2));

</script>

<template>
  <section class="home-slider position-relative mb-30">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <Slider :sliders="filteredSliders" />
        </div>
        <div class="col-lg-3">
          <div class="row">
            <div v-for="(data, index) in filteredOffers" :key="index" class="col-md-6 col-lg-12">
              <div class="banner-img style-4 mt-30">
                <img :src="sliderImageUrl + data.image" alt="" />
                <div class="banner-text">
                  <h4 class="mb-30">
                    {{data.title}}
                  </h4></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
