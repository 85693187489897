<script setup>

</script>

<template>
  <section class="section-padding footer-mid">
    <div class="container pt-15 pb-20">
      <div class="row">
        <div class="col">
          <div class="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
            <div class="logo mb-30">
              <router-link to="/" class="mb-15"><img :src="'/logo.png'" alt="logo" /></router-link>
            </div>
            <ul class="contact-infor">
              <li><img :src="'/frontend/assets/imgs/theme/icons/icon-location.svg'" alt="" /><strong>Address: </strong> <span>1,3,5 (Ground Floor), KalwalaPara, Mirpur-1, Dhaka-1216, Bangladesh</span></li>
              <li><img :src="'/frontend/assets/imgs/theme/icons/icon-contact.svg'" alt="" /><strong>Call Us:</strong><span>01941870110, 01763970006</span></li>
              <li><img :src="'/frontend/assets/imgs/theme/icons/icon-email-2.svg'" alt="" /><strong>Email:</strong><span> glowsightbd@gmail.com</span></li>
              <li><img :src="'/frontend/assets/imgs/theme/icons/icon-clock.svg'" alt="" /><strong>Hours:</strong><span>08:00 - 20:00, Sat - Friday</span></li>
            </ul>
          </div>
        </div>
        <div class="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
          <h4 class=" widget-title">Company</h4>
          <ul class="footer-list mb-sm-5 mb-md-0">
            <li><router-link to="/about-us">About Us</router-link></li>
            <li><router-link to="/delivery-information">Delivery Information</router-link></li>
            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
            <li><router-link to="/terms-conditions">Terms &amp; Conditions</router-link></li>
            <li><router-link to="/contact-us">Contact Us</router-link></li>
          </ul>
        </div>
        <div class="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
          <h4 class="widget-title">Account</h4>
          <ul class="footer-list mb-sm-5 mb-md-0">
            <li><router-link to="/sign-in">Sign In</router-link></li>
            <li><router-link to="/view-cart">View Cart</router-link></li>
            <li><router-link to="/my-wishlist">My Wishlist</router-link></li>
            <li><router-link to="/track-my-order">Track My Order</router-link></li>
            <li><router-link to="/shipping-details">Shipping Details</router-link></li>
            <li><router-link to="/compare-products">Compare products</router-link></li>
          </ul>
        </div>
        <div class="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
          <h4 class="widget-title">Corporate</h4>
          <ul class="footer-list mb-sm-5 mb-md-0">
            <li><router-link to="/">Become a Vendor</router-link></li>
            <li><router-link to="/">Farm Business</router-link></li>
            <li><router-link to="/">Farm Careers</router-link></li>
            <li><router-link to="/">Our Suppliers</router-link></li>
            <li><router-link to="/">Accessibility</router-link></li>
            <li><router-link to="/">Promotions</router-link></li>
          </ul>
        </div>
        <div class="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
          <h4 class="widget-title">Popular</h4>
          <ul class="footer-list mb-sm-5 mb-md-0">
            <li><router-link to="#">JUKI</router-link></li>
            <li><router-link to="#">BROTHER</router-link></li>
            <li><router-link to="#">YAMATO</router-link></li>
            <li><router-link to="#">PEGASUS</router-link></li>
            <li><router-link to="#">KANSAI</router-link></li>
            <li><router-link to="#">SIRUBA</router-link></li>
          </ul>
        </div>
        <div class="footer-link-widget widget-install-app col">
          <h4 class="widget-title">Install App</h4>
          <p class="wow fadeIn animated">From App Store or Google Play</p>
          <div class="download-app">
            <router-link to="#" class="hover-up mb-sm-2 mb-lg-0"><img class="active" :src="'/assets/imgs/app-store.jpg'" alt="" /></router-link>
            <router-link to="#" class="hover-up mb-sm-2"><img :src="'/assets/imgs/google-play.jpg'" alt="" /></router-link>
          </div>
          <p class="mb-20">Secured Payment Gateways</p>
          <img class="wow fadeIn animated" :src="'/assets/imgs/payment-method.png'" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
