<script setup>
import ProductSearch from "@/components/product-search/ProductSearch.vue";
import ProductShowToUser from "@/components/product-search/ProductShowToUser.vue";
import EventBus from "@/EventBus";
import { toast } from "vue3-toastify";
import {
  FeX,
  FeUser,
  FeLocation,
  FeHeart,
} from "@kalimahapps/vue-icons";
import { onMounted, ref } from "vue";
import ImageUrl from "@/ImageUrl";
import FetchData from "@/FetchData";

const compareCount = ref(0); // Use ref() to create reactive properties
const wishlistCount = ref(0);
const productCount = ref(0);
const products = ref([]);
const authenticated = ref(false);

const methods = {
  cartData() {
    const url = '/api/cart-data';
    FetchData(url, (data) => {
      products.value = data;
      productCount.value = data.length;
      EventBus.emit('sendData', data);
      toast.success(`There ${data.length} Products In your Quote`);
    });
  },
  wishListData() {
    const url = '/api/wishlist-data';
    FetchData(url, (data) => {
      wishlistCount.value = data.length;
    });
  },
  cartDelete(id) {
    const url = `/api/cart-delete/${id}`;
    FetchData(url, (data) => {
      if (data.message === 'Product Delete Success') {
        toast.success(data.message);
        this.cartData();
      } else {
        toast.error(data.message);
      }
    });
  },
};

onMounted(() => {
  EventBus.on('cartUpdate', () => {
    methods.cartData();
  });
  EventBus.on('wishlistUpdate', () => {
    methods.wishListData();
  });
  EventBus.on('cartDelete', (id) => {
    methods.cartDelete(id);
  });
  methods.cartData();
  methods.wishListData();
});
</script>
<template>
  <div class="header-middle header-middle-ptb-1 d-none d-lg-block">
    <div class="container">
      <div class="header-wrap">
        <div class="logo logo-width-1">
          <router-link to="/"> <img src="/logo.png" alt="GSB" /> </router-link>
        </div>
        <div class="header-right">
          <div class="search-style-2">
            <ProductSearch></ProductSearch>
          </div>
          <div class="header-action-right">
            <div class="header-action-2">
<!--              <div class="header-action-icon-2">-->
<!--                <router-link to="/compare">-->
<!--                  <img class="svgInject" alt="GSB" src="/assets/icons/icon-compare.svg" />-->
<!--                  <span class="pro-count blue" v-text="compareCount"></span>-->
<!--                </router-link>-->
<!--                <router-link to="/compare"><span class="lable ml-0">Compare</span></router-link>-->
<!--              </div>-->
              <div class="header-action-icon-2">
                <router-link to="/wishlist">
                  <img class="svgInject" alt="Nest" src="/assets/icons/icon-heart.svg" />
                  <span class="pro-count blue" v-text="wishlistCount"></span>
                </router-link>
                <router-link to="/wishlist"><span class="lable">Wishlist</span></router-link>
              </div>
              <div class="header-action-icon-2">
                <router-link class="mini-cart-icon" to="/quotation">
                  <img alt="GSB" src="/assets/icons/quotation.svg" />
                  <span class="pro-count blue" >{{productCount}}</span>
                </router-link>
                <router-link to="/quotation"><span class="lable">Quote</span></router-link>
                <form @submit.prevent="sendQuotation">
                  <div class="cart-dropdown-wrap cart-dropdown-hm2">
                    <ul>
                      <li v-for="(product, index) in products" :key="index">
                        <div class="shopping-cart-img">
                          <router-link to="/product-details-" target="_blank">
                            <img alt="GSB" :src="ImageUrl + product.product.image" />
                          </router-link>
                        </div>
                        <div class="shopping-cart-title">
                          <h4><router-link :to="`/product-details`+product.id" target="_blank">{{ product.product.name.slice(0, 15) }}</router-link></h4>
                          <h4><span>{{ product.qty }}</span></h4>
                        </div>
                        <div class="shopping-cart-delete">
                          <router-link to="#!" @click="methods.cartDelete(product.id)" @keydown.enter="methods.cartDelete(product.id)"><FeX class="text-danger"/></router-link>
                        </div>
                      </li>
                    </ul>
                    <div class="shopping-cart-footer">
                      <div class="shopping-cart-total">
                      </div>
                      <div class="shopping-cart-button">
                        <router-link to="/quotation" class="outline">View Quote</router-link>
                        <button type="submit" name="submit_quotation" class="outline">Quotation Send</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="header-action-icon-2">
                <router-link to="/my-account">
                  <img class="svgInject" alt="Nest" src="/assets/icons/icon-user.svg" />
                </router-link>
                <router-link to="/account"><span class="lable ml-0">Account</span></router-link>
                <div class="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                  <ul>
                    <li><router-link to="/account"><FeUser class="mr-10"/>My Account</router-link></li>
<!--                    <li v-if="authenticated"><a @click="userLogout" @keydown.enter="userLogout"><i class="fi fi-rs-user mr-10"></i>Logout</a></li>-->
                    <li ><router-link to="/login"><FeUser class="mr-10"/>Login</router-link></li>
                    <li ><router-link to="/register"><FeUser class="mr-10"/>Register</router-link></li>
                    <li><router-link to="/order-tracking"><FeUser class="mr-10"/>Order Tracking</router-link></li>
                    <li><router-link to="/wishlist"><FeHeart class="mr-10"/>My Wishlist</router-link></li>
                    <li><router-link to="/settings"><FeUser class="mr-10"/>Setting</router-link></li>
                    <li v-if="authenticated"><router-link to="" @click="userLogout" @keydown.enter="userLogout"><i class="fi fi-rs-sign-out mr-10"></i>Sign out</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductShowToUser></ProductShowToUser>
  </div>
</template>
