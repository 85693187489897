<script setup>
import TopProducts from "@/views/product/TopProducts.vue";
import { onMounted, ref } from "vue";
import CategoryProduct from "@/views/product/CategoryProduct.vue";
import Slider from "@/views/slider/Index.vue";
import fetchData from "@/FetchData";

const isLoading = ref(true);
const topProducts = ref([]);
const todayTopProducts = ref([]);
const topTitle = ref('Top #10 Selling Products');
const todayTopTitle = ref('Today Top #10 Selling Products');

const method = {
  topProducts() {
    const url = '/api/top-products';
    fetchData(url, (data) => {
      topProducts.value = data;
    });
    isLoading.value = false;
  },
  todayTopProducts() {
    const url = '/api/today-top-products';
    fetchData(url, (data) => {
      todayTopProducts.value = data;
    });
    isLoading.value = false;
  },
};
onMounted(() => {
  method.topProducts();
  method.todayTopProducts();
});
</script>
<template>
  <Slider/>
  <CategoryProduct/>
  <TopProducts :topTitle="topTitle" :topProducts="topProducts"/>
  <TopProducts :todayTopTitle="todayTopTitle" :todayTopProducts="todayTopProducts"/>
</template>
