<script setup>

</script>
<template>
  <div class="page-content pt-150 pb-150">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
          <p class="mb-20"><img :src="'/assets/imgs/page/page-404.png'" alt="" class="hover-up" /></p>
          <h1 class="display-2 mb-30">Page Not Found</h1>
          <p class="font-lg text-grey-700 mb-30">
            The link you clicked may be broken or the page may have been removed.<br />
            visit the <router-link to="/"> <span> Homepage</span></router-link> or <router-link to="/contact"><span>Contact us</span></router-link> about the problem
          </p>
          <router-link class="btn btn-default submit-auto-width font-xs hover-up mt-30" to="/"><i class="fi-rs-home mr-5"></i> Back To Home Page</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
