<script setup>
import { onMounted, ref } from "vue";
import EventBus from "@/EventBus";
import ManuList from "@/components/navbar/ManuList.vue";
import MobileProductSearch from "@/components/product-search/MobileProductSearch.vue";

// Define a ref for the div element
const sidebarDiv = ref(null);
const methods = {
  openMobile() {
    if (sidebarDiv.value) {
      sidebarDiv.value.classList.toggle('sidebar-visible');
    }
  },
};
onMounted(() => {
  EventBus.on('mobile-manu-toggle', () => {
    // Toggle the class on the div element
    if (sidebarDiv.value) {
      sidebarDiv.value.classList.toggle('sidebar-visible');
    }
  });
});

</script>

<template>
  <div ref="sidebarDiv" class="mobile-header-active mobile-header-wrapper-style">
    <div class="mobile-header-wrapper-inner">
      <div class="mobile-header-top">
        <div class="mobile-header-logo">
          <a href="index.html"><img src="assets/imgs/theme/logo.svg" alt="logo" /></a>
        </div>
        <div class="mobile-menu-close close-style-wrap close-style-position-inherit">
          <button @click="methods.openMobile()" @keydown.enter="methods.openMobile()" class="close-style search-close">
            <i class="icon-top"></i>
            <i class="icon-bottom"></i>
          </button>
        </div>
      </div>
      <div class="mobile-header-content-area">
        <div class="mobile-search search-style-3 mobile-header-border">
          <MobileProductSearch/>
        </div>
        <div class="mobile-menu-wrap mobile-header-border">
          <!-- mobile menu start -->
          <nav>
            <ul class="mobile-menu font-heading">
              <li class="menu-item-has-children">
                <a href="index.html">Home</a>
                <ul class="dropdown">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="index-2.html">Home 2</a></li>
                  <li><a href="index-3.html">Home 3</a></li>
                  <li><a href="index-4.html">Home 4</a></li>
                  <li><a href="index-5.html">Home 5</a></li>
                  <li><a href="index-6.html">Home 6</a></li>
                </ul>
              </li>
            </ul>
          </nav>
          <ManuList/>
          <!-- mobile menu end -->
        </div>
        <div class="mobile-header-info-wrap">
          <div class="single-mobile-header-info">
            <a href="page-contact.html"><i class="fi-rs-marker"></i> Our location </a>
          </div>
          <div class="single-mobile-header-info">
            <a href="page-login.html"><i class="fi-rs-user"></i>Log In / Sign Up </a>
          </div>
          <div class="single-mobile-header-info">
            <a href="#"><i class="fi-rs-headphones"></i>(+01) - 2345 - 6789 </a>
          </div>
        </div>
        <div class="mobile-social-icon mb-50">
          <h6 class="mb-15">Follow Us</h6>
          <a href="#"><img src="assets/imgs/theme/icons/icon-facebook-white.svg" alt="" /></a>
          <a href="#"><img src="assets/imgs/theme/icons/icon-twitter-white.svg" alt="" /></a>
          <a href="#"><img src="assets/imgs/theme/icons/icon-instagram-white.svg" alt="" /></a>
          <a href="#"><img src="assets/imgs/theme/icons/icon-pinterest-white.svg" alt="" /></a>
          <a href="#"><img src="assets/imgs/theme/icons/icon-youtube-white.svg" alt="" /></a>
        </div>
        <div class="site-copyright">Copyright 2022 © Nest. All rights reserved. Powered by AliThemes.</div>
      </div>
    </div>
  </div>
</template>
