<template>
  <header class="header-area header-style-1 header-style-5 header-height-2">
    <div class="mobile-promotion">
      <span>Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left</span>
    </div>
    <HeaderMiddle/>
    <HeaderBottom/>
    <MobileHeader/>
  </header>
</template>
<script >
import HeaderMiddle from "@/components/navbar/HeaderMiddle.vue";
import HeaderBottom from "@/components/navbar/HeaderBottom.vue";
import MobileHeader from "@/components/navbar/MobileHeader.vue";

export default {
  name: 'Navbar',
  components: {
    MobileHeader,
    HeaderBottom,
    HeaderMiddle,
  },
};
</script>
<style scoped>

</style>
