<script setup>
import {
  FeShoppingCart,
  FeHeart,
  FeEye,
  FeMessageCircle,
} from "@kalimahapps/vue-icons";
import ImageUrl from "@/ImageUrl";
import { computed, defineProps, ref } from "vue";
import EventBus from "@/EventBus";
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
  dataList: Array,
});
const dataList = computed(() => props.dataList);
const productName = computed(() => ({
  product(index) {
    const name = dataList?.value[index]?.name ? dataList?.value[index]?.name : 'example';
    return name.replace(/ /g, '_').replace(/&/g, '_&_').replace(/\//g, '_').replace(/\./g, '_');
  },
}));
const defaultImage = ref("/comeingSoon.png");
const methode = {
  addToQuote(id) {
    EventBus.emit('addToCart', id);
  },
  addToWishlist(id) {
    EventBus.emit('addToWishlist', id);
  },
  productViewModal(product) {
    EventBus.emit('productViewModal', product);
  },
  handleImageError(event) {
    // Set the source to the default image URL
    event.target.src = defaultImage.value;
  },
  openLink(id, name) {
    const routeData = router.resolve({ name: 'ProductDetails', params: { id, name } });
    const url = routeData.href;

    // Use router.push to navigate to the route
    router.push({ name: 'ProductDetails', params: { id, name } });

    // If you still want to change the window location
    window.location.href = url;
  },
};
</script>

<template>
  <div class="row product-grid">
    <div v-for="(product, index) in dataList" :key="index" class="col-lg-1-5 col-md-4 col-12 mt-10 col-sm-6 d-none d-xl-block">
      <div class="product-cart-wrap">
        <div class="product-img-action-wrap">
          <div class="product-img product-img-zoom">
            <router-link :to="{ name:'ProductDetails', params: { id: product.id}}" target="_blank">
              <img class="default-img" @error="methode.handleImageError" :src="ImageUrl+product.image" alt="" />
              <img class="hover-img" :src="ImageUrl+product.image" alt="" />
            </router-link>
          </div>
          <div class="product-action-1">
            <router-link aria-label="Add To Wishlist" @click="methode.addToWishlist(product.id)" @keydown.enter="methode.addToWishlist(product.id)" class="action-btn" to="#"><FeHeart/></router-link>
            <router-link aria-label="Add To Quote" @click="methode.addToQuote(product.id)" @keydown.enter="methode.addToQuote(product.id)" class="action-btn" to="#"><FeShoppingCart /></router-link>
            <a aria-label="Quick view" @click="methode.productViewModal(product.id)" @keydown.enter="methode.productViewModal(product.id)" class="action-btn" data-bs-toggle="modal" data-bs-target="#quickViewModal"><FeEye/></a>
          </div>
        </div>
        <div class="product-content-wrap">
<!--          <a href="" >{{ product.name }}</a>-->
          <a href="#!" @keydown.prevent="methode.openLink(product.id, productName.product(index))" @click.prevent="methode.openLink(product.id, productName.product(index))">{{ product.name }}</a>
<!--            <a @keydown.enter="methode.openLink(product.id, productName.product(index))" @click="methode.openLink(product.id, productName.product(index))">{{product.name}}</a>-->
          <div>
            <span class="font-small text-muted"><router-link :to="`/product-details/`+ product.id">{{product.part_number}}</router-link></span>
          </div>
          <div class="product-card-bottom">
            <div class="product-price">
              <span>৳ {{product.price}}</span>
              <span > - {{ (product.price * 2.0) }}</span>
            </div>
            <div class="add-cart">
              <router-link class="add" to="#!"><FeMessageCircle /> Add Quote</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
