import axios from "axios";
import { toast } from "vue3-toastify";

const defaultUrl = "https://globalpartsbd.store";
// Function for posting data
const postData = (url, data, successCallback, errorCallback) => {
  axios
    .post(defaultUrl + url, data)
    .then((res) => {
      if (res.data) {
        successCallback(res.data);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      if (errorCallback) {
        errorCallback(error);
      }
    });
};
export default postData;
