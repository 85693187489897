<script setup>
import { ref, onMounted } from "vue";
import Pagination from "@/components/pagination/Pagination.vue";
import EventBus from "@/EventBus";
import ProductList from "@/views/product/ProductList.vue";
import fetchData from "@/FetchData";
import Loading from "@/components/loading/Loading.vue";

const total = ref(0);
const categoryName = ref('');
const dataList = ref([]);
const pagination = ref({});
const isLoading = ref(false);

const changePage = (page) => {
  // Fetch data based on the selected page
  isLoading.value = true;
  const url = `/api/category-list-data?page=${page}`;
  fetchData(url, (data) => {
    pagination.value = data.data;
    dataList.value = data.data.data;
    total.value = data.data.total;
    categoryName.value = data.categoryName;
    isLoading.value = false;
  });
};
onMounted(() => {
  EventBus.on('page-change', (page) => {
    changePage(page);
  });
  changePage();
});

</script>

<template>
  <div class="container mb-30">
    <div class="row">
      <div class="col-12">
        <div class="shop-product-fillter">
          <div class="totall-product">
            <p>We found <strong class="text-brand">{{categoryName}}{{' '}}{{total}}</strong> items for you!</p>
          </div>
          <div class="sort-by-product-area">
            <pagination
              :current-page="pagination.current_page"
              :total-pages="pagination.last_page"
            />
          </div>
        </div>
        <div v-if="isLoading" class="row product-grid">
          <Loading/>
        </div>
        <ProductList v-else :dataList="dataList"/>
        <pagination
          :current-page="pagination.current_page"
          :total-pages="pagination.last_page"
        />
      </div>
    </div>
  </div>
</template>
